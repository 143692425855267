const chooseSelectColor = (isFocused, isSelected) => {
  if(isSelected) {
    return { background: "#333", color: "#ffffff"}
  } else if(isFocused) {
    return { background: "#f4f4f4", color: "#333"}
  } else {
    return { background: "#ffffff", color: "#000"}
  }
}

export const selectConfig = {
  control: styles => ({ 
      ...styles, 
      backgroundColor: '#f4f4f4',
      border: 'none',
      width: "100%"
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const selectColors = chooseSelectColor(isFocused, isSelected);

    return {
      ...styles,
      backgroundColor: selectColors.background,
      color: selectColors.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        backgroundColor: "#f4f4f4",
      },
      ':hover': {
        cursor: "pointer"
      }
    }
  },
}

const dot = (statusText) => {
  let color = "";

  if(statusText === "Active") {
    color = "#6FCF97"
  } else if(statusText === "Inactive") {
    color = "#DE440C"
  } else if(statusText === "Draft") {
    color = "#ccc"
  }

  return {
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  }
}

const chooseStatusSelectColor = (isFocused, isSelected) => {
  if(isSelected) {
    return { background: "#f4f4f4", color: "#333"}
  } else if(isFocused) {
    return { background: "#f4f4f4", color: "#333"}
  } else {
    return { background: "#ffffff", color: "#000"}
  }
}


const chooseWidth = (statusText) => {
  let width = "";

  if(statusText === "Active") {
    width = "105px"
  } else if(statusText === "Inactive") {
    width = "115px"
  } else if(statusText === "Draft") {
    width = "100px"
  }
}

export const statusSelectConfig = {
  menu: (provided, state) => ({
    ...provided,
    color: "#fff",
  }),
  control: styles => ({ 
      ...styles, 
      backgroundColor: 'transparent',
      color: "#fff !important",
      border: 'none',
      boxShadow: 'none',
      padding: 0,
      ':hover': {
        ...styles[':hover'],
        cursor: "pointer"
      }    
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    ...dot(data.value),
    color: "#fff",
    fontSize: "1.1rem",
    padding: 0,
    ':hover': {
      ...styles[':hover'],
      cursor: "pointer"
    },
    maxWidth: chooseWidth(data.value)
  }),
  container: (styles, {data}) => ({
    ...styles,
    border: "none",
    outline: "none",
    ':hover': {
      ...styles[':hover'],
      cursor: "pointer"
    },
    width: "100%",
    marginTop: "2px"
  }),
  input: styles => ({
    ...styles,
  }),
  valueContainer: styles =>({
    ...styles,
    padding: 0,
    border: "none",
    outline: "none",
    ':hover': {
      ...styles[':hover'],
      cursor: "pointer"
    }
  }),
  dropdownIndicator: styles => ({
    ...styles,
    color: "#fff !important",
    marginLeft: "-5px",

  }),
  indicatorSeparator: (styles) => ({display:'none'}),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const selectColors = chooseStatusSelectColor(isFocused, isSelected);

    return {
      ...styles,
      ...dot(data.value),
      backgroundColor: selectColors.background,
      color: selectColors.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: chooseStatusSelectColor(isFocused, isSelected),
      },
      ':hover': {
        ...styles[':hover'],
        cursor: "pointer",
      },
    }
  },
}