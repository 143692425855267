const states = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FM": "Federated States Of Micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin Islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
}

const jobTitleOptions = {
  1: [
    { value: 1, label: "Electrical Helper" },
    { value: 2, label: "Apprentice Electrician" },
    { value: 3, label: "Journeyman Electrician" },
    { value: 4, label: "Master Electrician" },
    { value: 8, label: "Service/Maintenance Electrician" },
    { value: 9, label: "Low Voltage/Cable Technician" },
    { value: 10, label: "Lighting Technician" },
    { value: 11, label: "Lighting Retrofitter" },
    { value: 12, label: "Events/Production Electrician" },
    { value: 13, label: "Service Manager" },
    { value: 36, label: "Designer/Drafter" },
    { value: 5, label: "Foreman" },
    { value: 6, label: "General Foreman" },
    { value: 7, label: "Superintendent" },
    { value: 14, label: "Quality Control Manager" },
    { value: 15, label: "Safety Manager" },
    { value: 16, label: "Project Manager" },
    { value: 17, label: "Estimator" }
  ],
  2: [
    { value: 18, label: "Concrete Laborer" },
    { value: 19, label: "Apprentice Formsetter" },
    { value: 20, label: "Journeyman Formsetter" },
    { value: 21, label: "Apprentice Cement Mason" },
    { value: 22, label: "Journeyman Cement Mason" },
    { value: 23, label: "Concrete Finisher" },
    { value: 24, label: "Concrete Mixer Driver" },
    { value: 25, label: "Concrete Inspector" },
    { value: 5, label: "Foreman" },
    { value: 6, label: "General Foreman" },
    { value: 7, label: "Superintendent" },
    { value: 14, label: "Quality Control Manager" },
    { value: 15, label: "Safety Manager" },
    { value: 16, label: "Project Manager" },
    { value: 17, label: "Estimator" }
  ],
  3: [
    { value: 26, label: "Laborer" },
    { value: 5, label: "Foreman" },
    { value: 6, label: "General Foreman" },
    { value: 7, label: "Superintendent" },
    { value: 14, label: "Quality Control Manager" },
    { value: 15, label: "Safety Manager" },
    { value: 16, label: "Project Manager" },
    { value: 17, label: "Estimator" }
  ],
  4: [
    { value: 27, label: "Carpenter Helper" },
    { value: 28, label: "Apprentice Carpenter" },
    { value: 29, label: "Journeyman Carpenter" },
    { value: 30, label: "Master Carpenter" },
    { value: 31, label: "Drywall Installer" },
    { value: 32, label: "Drywall Finisher" },
    { value: 33, label: "Stair Carpenter" },
    { value: 34, label: "Floor Installer" },
    { value: 35, label: "Cabinet Maker" },
    { value: 5, label: "Foreman" },
    { value: 6, label: "General Foreman" },
    { value: 7, label: "Superintendent" },
    { value: 14, label: "Quality Control Manager" },
    { value: 15, label: "Safety Manager" },
    { value: 16, label: "Project Manager" },
    { value: 17, label: "Estimator" }
  ]
}

class OptionGenerator {
  static workerFilterOptions(filters) {
    return {
      workTypes: filters.work_types.map(work_type => {
        return { value: work_type.id, label: work_type.name }
      }),

      projectTypes: filters.project_types.map(project_type => {
        return { value: project_type.id, label: project_type.name }
      }),

      industries: filters.industries.map(industry => {
        return { value: industry.id, label: industry.name }
      }),

      jobTitles: filters.job_titles.map(jobTitle => {
        return { value: jobTitle, label: jobTitle }
      }),

      titles: filters.titles.map(title => {
        return { value: title.id, label: title.name }
      }),

      trainings: filters.trainings.map(training => {
        return { value: training, label: training }
      }),

      trades: filters.trades.map(trade => {
        return { value: trade.id, label: trade.name }
      }),

      skills: Object.keys(filters.skills || []).map((category) => {
        const cData =  {
          label: category,
          options: filters.skills[category].map(skill => { 
            return { value: skill.id, label: skill.name }
          })
        }

        return cData;
      }),
      certifications: Object.keys(filters.certifications || []).map((category) => {
        return {
          label: category,
          options: filters.certifications[category].map(cert => {
            return { value: cert.id, label: cert.name }
          })
        }
      }),
      states: Object.keys(states).map(key => {
        return { label: states[key], value: key }
      }),
      minExperienceYears: [...Array(41).keys()].map((year) => {
        const yearLabel = year != 1 ? "years" : "year";
        return { value: year, label: `${year} ${yearLabel}`}
      })
    }
  }

  static workerFilterDefaults(skills, certs, titles, workTypes, industries, projectTypes, trainings, state, minExperienceYear, trades) {
    return {
      skills: skills.map(item => {
        return { value: item.value, label: item.label }
      }),

      certs: certs.map(item => {
        return { value: item.value, label: item.label }
      }),

      titles: titles.map(item => {
        return { value: item.value, label: item.label }
      }),

      workTypes: workTypes.map(item => {
        return { value: item.value, label: item.label }
      }),

      industries: industries.map(item => {
        return { value: item.value, label: item.label }
      }),

      projectTypes: projectTypes.map(item => {
        return { value: item.value, label: item.label }
      }),

      trainings: trainings.map(item => {
        return { value: item.value, label: item.label }
      }),
      trades: trades.map(item => {
        return { value: item.value, label: item.label }
      }),
      state: { value: (state ? state.value : ""), label: (state ? state.label : "") },
      minExperienceYear: { value: (minExperienceYear ? minExperienceYear.value : ""), label: (minExperienceYear ? minExperienceYear.label : "") }
    }
  }

  static workerFieldOptions(fields, jobs, employers, trade) {
    const filteredSkills = () => {
      let tradeId = null;
      if(trade) tradeId = trade.value
      
      const sCategories = Object.keys(fields.skills)
  
      const newSkills = {};
      sCategories.forEach(category => {
        const filtered = fields.skills[category].filter(skill => {
          return (skill.trade_id === tradeId || skill.trade_id === null)
        });
    
        if(filtered.length > 0) newSkills[category] = filtered;
      });
  
      return newSkills;
    }
  
    const filteredCerts = () => {
      let tradeId = null;
      if(trade) tradeId = trade.value
      
      const sCategories = Object.keys(fields.certifications)
  
      const newCerts = {};
      sCategories.forEach(category => {
        const filtered = fields.certifications[category].filter(cert => {
          return (cert.trade_id === tradeId || cert.trade_id === null)
        });
  
        if(filtered.length > 0) newCerts[category] = filtered;
      });
  
      return newCerts;
    }

    const skills = filteredSkills();
    const certifications = filteredCerts();

    let options = {
      trades: fields.trades.map(trade => {
        return { value: trade.id, label: trade.name }
      }),

      workTypes: fields.work_types.map(work_type => {
        return { value: work_type.id, label: work_type.name }
      }),

      projectTypes: fields.project_types.map(project_type => {
        return { value: project_type.id, label: project_type.name }
      }),

      industries: fields.industries.map(industry => {
        return { value: industry.id, label: industry.name }
      }),

      titles: fields.titles.filter(title => {
        if(trade) {
          return title.trade_id === trade.value || title.trade_id === null || title.trade_id === undefined
        }

        return title.trade_id === null || title.trade_id === undefined
      }).map(title => {
        return { value: title.id, label: title.name }
      }),

      trainings: fields.trainings.map(training => {
        return { value: training, label: training }
      }),

      skillCategories: Object.keys(skills || []).map((category) => {
        return { value: category, label: category }
      }),

      certificationCategories: Object.keys(certifications || []).map((category) => {
        return { value: category, label: category }
      }),

      experienceYears: [...Array(41).keys()].map((year) => {
        const yearLabel = year != 1 ? "years" : "year";
        return { value: year, label: `${year} ${yearLabel}`}
      })
    }

    if(jobs && jobs.length > 0) {
      const jobLabel = (jobObj) => {
        return (jobObj.name + " - " + jobObj.location + " (" + jobObj.created_at + ")")
      }

      options.jobs = jobs.map(job => {
        return { value: job.identifier, label: jobLabel(job)}
      })
    }

    if(employers && employers.length > 0) {
      options.employers = employers.map(employer => { return { label: employer[0], value: employer[1] } })
    }

    options.skills = skills;
    options.certifications = certifications;

    return options;
  }

  static workerFieldDefaults(worker) {
    if(!worker) return {};

    return {
      skills: (() => {
        let dSkills = {}

        worker.skills.forEach(skill => {
          dSkills[skill.id] = skill;
        })

        return dSkills;
      })(),
      certifications: (() => {
        let dCerts = {}

        worker.certifications.forEach(cert => {
          dCerts[cert.id] = cert;
        })

        return dCerts;
      })(),

      jobTitle: worker.job_title ? { value: worker.job_title, label: worker.job_title } : null,

      workTypes: worker.work_types.map(item => {
        return { value: item.id, label: item.name }
      }),

      industries: worker.industries.map(item => {
        return { value: item.id, label: item.name }
      }),

      projectTypes: worker.project_types.map(item => {
        return { value: item.id, label: item.name }
      }),

      training: worker.training ? { value: worker.training, label: worker.training } : null,

      trade: worker.trade ? { value: worker.trade.id, label: worker.trade.name } : null,
      
      title: worker.title ? { value: worker.title.id, label: worker.title.name } : null,

      yearsOfExperience: { 
        value: worker.years_of_experience, label: (worker.years_of_experience === 1 ? `${worker.years_of_experience} year` : `${worker.years_of_experience} years`)
      },
    }
  }
  
  static jobFilterOptions(filterOptions, selectedFilters) {
    return {
      projectTypes: (filterOptions.project_types != undefined) ? filterOptions.project_types.map(project_type => {
        const selected = selectedFilters.has("project_type-" + project_type.id);
        return { value: project_type.id, label: project_type.name, selected: selected}
      }).filter(item => !item.selected) : [],

      workTypes: (filterOptions.work_types != undefined) ? filterOptions.work_types.map(work_type => {
        const selected = selectedFilters.has("work_type-" + work_type.id);
        return { value: work_type.id, label: work_type.name, selected: selected}
      }).filter(item => !item.selected) : [],

      industries: (filterOptions.industries != undefined) ? filterOptions.industries.map(industry => {
        const selected = selectedFilters.has("industry-" + industry.id);
        return { value: industry.id, label: industry.name, selected: selected }
      }).filter(item => !item.selected) : [],

      titles: (filterOptions.titles != undefined) ? filterOptions.titles.map(title => {
        const selected = selectedFilters.has("title-" + title.id);
        return { value: title.id, label: title.name, selected: selected }
      }).filter(item => !item.selected) : [],

      locations: (filterOptions.locations != undefined) ? filterOptions.locations.map(location => {
        const selected = selectedFilters.has("location-" + location.id);
        return { value: location.id, label: location.name, selected: selected }
      }).filter(item => !item.selected) : [],

      minExperienceYears: (filterOptions.min_years_of_experience != undefined) ? filterOptions.min_years_of_experience.map((year) => {
        const selected = selectedFilters.has("min_years_of_experience-" + year.id);
        return { value: year.id, label: year.name, selected: selected }
      }).filter(item => !item.selected) : []
    };
  };
};

export { OptionGenerator, jobTitleOptions }